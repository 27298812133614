<template>
  <section>
    <folha>
      <div class="header maiuscula" style=" ">
          <h3> <strong>NOME:</strong> {{ paciente.nome }} </h3>
          <h3> <strong>DATA DO EXAME:</strong> {{ consulta.data }} </h3>
          <h3> <strong>CONVÊNIO:</strong> {{ convenio.nome }} </h3>
        </div>

      <h3>TRANSLUCÊNCIA NUCAL + AVALIAÇÃO MORFOLÓGICA DO PRIMEIRO TRIMESTRE</h3>

      <p>Exame realizado com transdutor {{laudo.transdutor}} de alta frequência. </p>

      <p>Data da última menstruação {{laudo.dataUltimaMestrucao}} </p>

      <p>Idade gestacional (DUM) {{}} </p>

      <p>Gestação {{laudo.gestacao}} , situação variável ao exame. </p>

      <p>Placenta {{laudo.placenta}} , grau 0. </p>

      <p>Comprimento cabeça-nádegas: {{laudo.comprimentoCabecaNadegas}}mm.</p>

      <p>Frequencia Cardíaca fetal: {{laudo.frequenciaCardiacaFetal}}bpm.</p>

      <p>Diâmetro biparietal {{laudo.diametroBiparietalPrimeiroTrimestre}}mm.</p>

      <p>Circunferência cefálica: {{laudo.circunferenciaCefalicaPrimeiroTrimestre}}mm.</p>

      <p>Circunferência abdominal: {{laudo.circunferenciaAbdominalPrimeiroTrimestre}}mm.</p>

      <p>Fêmur: {{laudo.femurPrimeiroTrimestre}}mm.</p>

      <p>Peso: {{laudo.pesoPrimeiroTrimestre}}mm.</p>

      <p>Morfologia fetal: </p>

      <p>Crânio: {{laudo.cranioPrimeiroTrimestre}}</p>

      <p>Sistema nervoso central: {{laudo.sistemaNervosoCentralPrimeiroTrimistre}}</p>

      <p>Translucência intracraniana: {{laudo.translucenciaInstracranianaPrimeiroTrimistre}} <span v-if="translucenciaInstracranianaPrimeiroTrimistre === 'presente medindo'"> {{laudo.translucenciaInstracranianaPrimeiroTrimistreMedindo}}mm.</span></p>

      <p>Órbitas: {{laudo.orbitasPrimeiroTrimistre}} </p>

      <p>Perfil fetal: {{laudo.perfilFetalPrimeiroTrimistre}} </p>

      <p>Estômago: {{laudo.estomagoPrimeiroTrimistre}} </p>

      <p>Bexiga: {{laudo.bexigaPrimeiroTrimistre}} </p>

      <p>Parede abdominal: {{laudo.paredeAbdominalPrimeiroTrimistre}} </p>

      <p>Cordão umbilical: {{laudo.cordaoUmbilicalPrimeiroTrimistre}} inserido na {{laudo.cordaoUmbilicalInseridoNa}}</p>

      <p>Membros superiores {{laudo.membrosSuperioresPrimeiroTrimistre}}</p>

      <p>Membros inferiores {{laudo.membrosInferioresPrimeiroTrimistre}}</p>

      <p>Artérias renais {{laudo.artereiasRenaisPrimeiroTrimistre}}</p>

    </folha>

    <folha>

      <p><h4>Marcadores Para Cromossomopatias:</h4></p>

      <p>Translucência nucal: {{laudo.transulucenciaNucal}} mm.</p>

      <p>Osso nasal: {{laudo.ossoNasal}}</p>

      <p>Fluxo Tricúspide: {{laudo.fluxoTriscupide}}</p>

      <p>Ducto venoso: {{laudo.ductoVenoso}}</p>

      <p><h4>Risco Para Trissomias:</h4></p>

      <p>Risco basal (baseado na idade materna):</p>

      <p>Trissomia do 21: {{laudo.trissomia21Materna}} mm.</p>

      <p>Risco corrigido (baseado na idade materna e TN):</p>

      <p>Trissomia do 21: {{laudo.trissomia21MaternaTN}} mm.</p>

      </folha>
      
      <folha>

      <p><h4>Conclusão: </h4></p>

      <p>Gestação {{laudo.gestacao2}}
          <span v-if="laudo.gestacao2 === 'gemelar'">
              {{laudo.gestacao3}} {{laudo.gestacao4}}
          </span>

          <span v-if="laudo.gestacao2 === 'trigemelar'">
              {{ laudo.comentariosAdcionaisTrigemelar}}
          </span>

          <span v-if="laudo.gestacao2 === 'quadrigemelar'">
              {{laudo.comentariosAdcionaisQuadrigemelar}}
          </span>

          <span v-if="laudo.gestacao2 === 'quíntupla'">
              {{laudo.comentariosAdcionaisQuintupla}}
          </span>
      </p>

      <p>Idade gestacional baseada na biometria fetal atual de {{laudo.idadeGestacionalBiometriaFetalSemanas}} semanas e {{laudo.idadeGestacionalBiometriaFetalDias}} dias, {{laudo.compativelIncompativel}} com a idade gestacional baseada na DUM.</p>

      <p>Data provável do parto: {{laudo.dataProvavelParto}} </p>

      <p>O risco de trissomia do 13, 18 e 21 {{laudo.riscoTrissomia}} após a medida da TN no feto avaliado. </p>

      <p>Sendo considerado de {{laudo.taxaRisco}} para cromossomopatias.</p>

      <p>{{laudo.morfologiafetalIdadeGestacional}} <span v-if="laudo.morfologiafetalIdadeGestacional === 'Alterações precoces da mofologia fetal, caracterizada por'"> {{laudo.morfologiafetalIdadeGestacionalOutros}} </span></p>

      <p>{{laudo.conclusao3}}</p>

      <p>Comentários Adcionais: {{laudo.comentariosAdcionaisFinal1}}</p>

      <p><h4>Notas: </h4></p>

      <p>A Fetal Medicine Foundation calcula o risco de trissomia de acordo com o risco corrigido pela TN em:</p>
      <p>■ Baixo o risco, o risco corrigido < 1:1000</p>
      <p>■ Risco intermediário, o risco corrigido entre ≤ 1:51 - 1:1000</p>
      <p>■ Risco alto, o risco corrigido entre ≤ 1:50</p>
      <p>*Risco corrigido baixo - avaliação morfológica entre 20 e 24 semanas.</p>
      <p>*Risco corrigido intermediário - Incluir a avaliação do osso nasal, do ducto venoso, da valva tricúspide e dos marcadores bioquímicos.</p>
      <p>*Risco corrigido alto - indicação de investigação adicional (biópsia de vilo corial ou amniocentese).</p>

      <p>Notas: {{laudo.notasFinal1}}</p>

    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
export default {
  mixins: [ImprecoesMixins],
};
</script>

<style lang="scss" scoped>
$default-spacer: 8px;

section {
  text-align: justify;
  font-size: 12pt;

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: $default-spacer * 4 0;
  }

  h4 {
    margin: $default-spacer * 2 0;
  }

  p {
    margin: $default-spacer 0;
  }
}
</style>